import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Architects = () => {
    return (
        <Layout>
                            <Seo
      title="Arquitectos - BIKESAFE"
      description="Bike Safe ofrece una gama completa de productos que combinan una gran seguridad con diseños estéticos y escultóricos a un precio razonable. Ponemos gran énfasis en estos aspectos para poder fomentar el uso de “bicicletas seguras y bicicletas estables”. Los arquitectos y planificadores de obra considerarán que todos los productos de Bike Safe están en consonancia con sus altos niveles de calidad y funcionamiento."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Architects
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Bicicletas seguras y bicicletas estables:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Bike Safe ofrece una gama completa de productos que combinan una gran seguridad con diseños estéticos y escultóricos a un precio razonable. Ponemos gran énfasis en estos aspectos para poder fomentar el uso de “bicicletas seguras y bicicletas estables”. Los arquitectos y planificadores de obra considerarán que todos los productos de Bike Safe están en consonancia con sus altos niveles de calidad y funcionamiento.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/projects.png" className="shadow fluid rounded " />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Diseño CAD en la propia empresa:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Bike Safe ofrece un completo servicio de diseño que incluye una visita al emplazamiento y un completo servicio de diseño CAD. Stephen, nuestros diseñador CAD, cuenta con una amplia experiencia de colaboración con arquitectos para generar diseños innovadores y únicos. Pida más información sobre los precios si le interesa.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Architects;